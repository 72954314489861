<template>
  <div v-if="value"
    class="tile is-parent">
    <article class="tile is-child box">
      <div class="columns mb-0">
        <div class="column is-flex is-align-items-center">
          <i class="icon mdi mdi-car mdi-24px" />
          <p class="title pl-1">Vehicle</p>
        </div>
        <div class="column is-flex is-justify-content-flex-end">
          <div v-if="ntarLtarStatus && innerVehicle.model && (isOrm || isNtar)">
            <span class="tag is-success animate__animated animate__pulse animate__repeat-3 tooltip"
              :data-tooltip="`${ntarLtarStatus} schedule and times found`">{{ ntarLtarStatus }} Hours On - {{ innerVehicle.model.scheduleId }}</span>
          </div>
          <div v-if="!ntarLtarStatus && innerVehicle.model && (isOrm || isNtar)">
            <span class="tag is-danger animate__animated animate__pulse animate__repeat-3 tooltip"
              :data-tooltip="`NTAR/LTAR  times not found`">NTAR/LTAR Hours OFF</span>
          </div>
        </div>
      </div>
      <div class="is-divider" />
      <div class="field">
        <div class="field-body">
          <div class="field">
            <label class="label is-required-label"
              style="white-space: nowrap">Registration No.</label>
            <div class="field">
              <div class="control">
                <bulma-autocomplete :class-prop="!$v.vehicle.rego.$error ? 'is-uppercase is-required-field' : 'is-uppercase is-invalid'"
                  :value="innerVehicle.rego"
                  :data="vehicles"
                  field="rego"
                  :loading="isSearchingVehicle"
                  :expanded="true"
                  ref="vehicleAutocomplete"
                  @input="searchVehicle"
                  @select="selectVehicle"
                  @onEnter="handleVehiclePickerEnter">
                  <template slot-scope="props">
                    <div>{{ props.option.rego }}</div>
                    <div class="help is-primary">{{ props.option.model }}</div>
                    <div class="help is-primary">{{ props.option.ownersName }}</div>
                  </template>
                </bulma-autocomplete>
              </div>
            </div>
          </div>
          <div v-if="$company.info.countryCode === 'AU'"
            class="field is-align-self-flex-end">
            <label class="label">State</label>
            <div class="control is-extended">
              <div class="select is-fullwidth"
                style="width: 100px">
                <select v-model="innerVehicle.stateRego">
                  <option value="null"
                    disabled>Select</option>
                  <option v-for="(a,b) in stateRegoTypes"
                    :value="b"
                    :key="b">{{ a }}</option>
                </select>
              </div>
            </div>
          </div>
          <div v-if="$company.info.countryCode === 'AU'"
            class="field is-flex-grow-0 is-align-self-flex-end">
            <button class="button is-primary is-rounded"
              @click="isVehicleLookupModalActive = true">
              <span class="icon">
                <i class="mdi mdi-magnify mdi-24px" />
              </span>
              <span>Rego Lookup</span>
            </button>
          </div>
        </div>
      </div>
      <div v-if="false"
        class="field">
        <div class="field has-addons">
          <div class="control is-expanded">
            <multiselect v-if="vehicles"
              id="quote-vehicle-multiselect"
              :class="[!$v.vehicle.id.$error ? 'is-required-field' : 'is-invalid']"
              v-model="selectedVehicle"
              deselect-label="Can't remove this value"
              track-by="vehicleId"
              label="rego"
              placeholder="Search By Rego..."
              :options="vehicles"
              :loading="isSearchingVehicle"
              :internal-search="false"
              :options-limit="vehicleFilter.pageSize"
              @select="selectVehicle"
              @remove="removeVehicle"
              @search-change="getVehicleDropdownFilter"
              :searchable="true"
              :show-labels="false"
              :allow-empty="false">
              <template slot="singleLabel"
                slot-scope="{ option }">
                {{ option.rego }}
              </template>
              <template slot="option"
                slot-scope="props">
                <div>{{ props.option.rego }}</div>
                <div class="help is-primary">{{ props.option.model }}</div>
              </template>
              <span class="has-text-danger"
                slot="noResult">Vehicle not found.</span>
            </multiselect>
          </div>
          <div class="control">
            <button class="button is-primary tooltip"
              data-tooltip="Rego lookup service"
              @click="isVehicleLookupModalActive = true">
              <span class="icon">
                <i class="mdi mdi-magnify mdi-24px" />
              </span>
            </button>
          </div>
        </div>
      </div>
      <div class="field">
        <div class="field-body">
          <div class="field">
            <label class="label is-required-label">Make / Model</label>
            <div class="field has-addons">
              <div class="control is-expanded">
                <bulma-autocomplete :class-prop="!$v.vehicle.model.$error ? 'is-required-field' : 'is-invalid'"
                  :value="makeModelText"
                  :data="filteredMakeModels"
                  field="name"
                  @input="searchMakeModel"
                  @select="selectMakeModel"
                  :loading="isSearchingMakeModel"
                  :expanded="true">
                  <template slot-scope="props">
                    <div>{{ props.option.name }}</div>
                    <div class="help is-primary">{{ props.option.cylinders }} Cylinders</div>
                    <div class="help is-primary">{{ props.option.production }}</div>
                  </template>
                </bulma-autocomplete>
              </div>
              <div class="control">
                <button class="button has-text-success tooltip"
                  data-tooltip="Add unscheduled model"
                  @click="addModel"
                  v-if="modelId === ''">
                  <span class="icon is-small">
                    <i class="mdi mdi-plus mdi-24px" />
                  </span>
                </button>
                <button class="button has-text-primary tooltip"
                  data-tooltip="Edit unscheduled model"
                  @click="editModel"
                  v-if="modelId !== '' && innerVehicle.model.isUnscheduled">
                  <span class="icon is-small">
                    <i class="mdi mdi-pencil mdi-24px" />
                  </span>
                </button>
                <button class="button has-text-success tooltip"
                  data-tooltip="Create unscheduled model"
                  @click="copyModel"
                  v-if="modelId !== '' && !innerVehicle.model.isUnscheduled">
                  <span class="icon is-small">
                    <i class="mdi mdi-plus mdi-24px" />
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div class="field is-flex-grow-0 is-align-self-flex-end">
            <div class="control">
              <button class="button is-primary is-light is-rounded"
                @click="isCarModelSearchModalActive = true">
                <span class="icon is-small">
                  <i class="mdi mdi-magnify mdi-24px" />
                </span>
                <span>Find</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="columns field">
        <div class="column">
          <div class="field">
            <label class="label is-required-label">Body Style</label>
            <div class="control is-expanded">
              <div class="select is-fullwidth"
                :class="!$v.vehicle.bodyId.$error ? 'is-required-field' : 'is-invalid'">
                <select v-model="innerVehicle.bodyId"
                  @change="onChangeBodyStyle">
                  <option value="null"
                    disabled>Select</option>
                  <option v-for="(s, index) in bodyStyles"
                    :key="index"
                    :value="s.id">
                    {{ s.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <label class="label">Body Style 2</label>
            <div class="control is-extended">
              <div class="select is-fullwidth"
                :class="{ 'is-invalid': $v.vehicle.bodyId2.$error }"
                content="Required by ORM."
                v-tippy="{ theme: 'danger', enabled: $v.vehicle.bodyId2.$error}">
                <select ref="bodyId2"
                  v-model="innerVehicle.bodyId2">
                  <option value="null"
                    disabled>Select</option>
                  <option v-for="(s, index) in bodyStyles2"
                    :key="index"
                    :value="s.id">
                    {{ s.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="columns field">
        <div class="column">
          <div class="field">
            <label class="label is-required-label">Paint Group</label>
            <div :class="{ 'is-flex': innerVehicle.paintGroup === paintGroupTypes.M3 }">
              <div class="control is-expanded">
                <div class="select is-fullwidth"
                  :class="!$v.vehicle.paintGroup.$error ? 'is-required-field' : 'is-invalid'">
                  <select v-model="innerVehicle.paintGroup"
                    class=" control-min-width"
                    ref="paintGroup"
                    @change="selectPaintGroup($event)">
                    <option value="null"
                      disabled>Select</option>
                    <option v-for="(p, index) in paintGroups"
                      :key="index"
                      :value="p.key">
                      {{ p.value }}
                    </option>
                  </select>
                </div>
              </div>
              <div v-if="innerVehicle.paintGroup === paintGroupTypes.M3"
                class="control is-flex is-align-items-center pl-4">
                <div class="pretty p-icon p-curve p-smooth">
                  <input v-model="innerVehicle.paintGroupPlus"
                    type="checkbox">
                  <div class="state p-primary">
                    <i class="icon mdi mdi-check" />
                    <label>M3+</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <label class="label">Build Date</label>
            <div class="field has-addons">
              <div class="control">
                <div class="select"
                  :class="{ 'is-invalid': $v.vehicle.buildMonth.$error }"
                  content="Required by ORM."
                  v-tippy="{ theme: 'danger', enabled: $v.vehicle.buildMonth.$error }">
                  <select v-model="innerVehicle.buildMonth">
                    <option value="null"
                      disabled>MM</option>
                    <option v-for="(month, key) in months"
                      :value="key"
                      :key="key">{{ month }}</option>
                  </select>
                </div>
              </div>
              <p class="control">
                <the-mask class="input"
                  :class="{ 'is-invalid': $v.vehicle.buildYear.$error }"
                  placeholder="Year"
                  v-model="innerVehicle.buildYear"
                  :mask="['####']"
                  @blur.native="checkYear"
                  content="Required by ORM."
                  v-tippy="{ theme: 'danger', enabled: $v.vehicle.buildYear.$error }" />
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="columns field">
        <div class="column">
          <div class="field">
            <label class="label">Transmission</label>
            <div class="control is-extended">
              <div class="select is-fullwidth"
                :class="{ 'is-invalid': $v.vehicle.transmissionType.$error }">
                <select v-model="innerVehicle.transmissionType">
                  <option value="null"
                    disabled>Select</option>
                  <option v-for="(a,b) in transmissionTypes"
                    :value="b"
                    :key="b">{{ a }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <label class="label">Colour</label>
            <p class="control">
              <bulma-autocomplete class="is-capitalized control-min-width"
                v-model="innerVehicle.colour"
                :data="colours"
                :expanded="true"
                :class-prop="$v.vehicle.colour.$error ? 'is-invalid' : ''" />
            </p>
          </div>
        </div>
      </div>
      <div class="field">
        <div class="field-body">
          <div class="field">
            <label class="label">VIN</label>
            <p class="control">
              <the-mask name="vin"
                v-model="innerVehicle.vin"
                :mask="vinMask"
                :tokens="vinTokens"
                class="input is-uppercase" />
            </p>
            <span class="help is-danger"
              v-if="!$v.vehicle.vin.customMinLength">Vin should have at least 7 characters</span>
            <span class="help is-danger"
              v-if="!$v.vehicle.vin.maxLength">Vin max 17 characters</span>
          </div>
          <div class="field">
            <label class="label">Engine No.</label>
            <div class="control">
              <input v-model="innerVehicle.engineNo"
                class="input"
                type="text">
            </div>
          </div>
        </div>
      </div>
      <div class="field">
        <div class="field-body">
          <div class="field">
            <label class="label">Odometer</label>
            <div class="control">
              <the-mask class="input"
                :class="{ 'is-invalid': $v.entity.odometer.$error }"
                placeholder="e.g. 6"
                v-model="innerQuote.odometer"
                :mask="['##########']"
                content="Required by ORM. Must be greater than 0 and less than 10,000,000."
                v-tippy="{ theme: 'danger', enabled: $v.entity.odometer.$error }" />
            </div>
          </div>
        </div>
      </div>
      <div class="field">
        <div class="field-body">
          <div class="field">
            <label class="label">Cylinders</label>
            <div class="control">
              <the-mask class="input"
                placeholder="e.g. 6"
                v-model="innerVehicle.cylinders"
                :mask="['###']" />
            </div>
          </div>
          <div class="field">
            <label class="label">Engine Size</label>
            <div class="control">
              <the-mask class="input"
                placeholder="e.g. 1300"
                v-model="innerVehicle.engineSize"
                :mask="engineSizeMask"
                :tokens="engineSizeTokens" />
            </div>
          </div>
        </div>
      </div>
      <div class="field">
        <div class="field-body">
          <div class="field">
            <label class="label">Trim Code</label>
            <div class="control">
              <input v-model="innerVehicle.trimCode"
                class="input"
                type="text">
            </div>
          </div>
          <div class="field">
            <label class="label">Paint Code</label>
            <div class="control">
              <input v-model="innerVehicle.paintCode"
                class="input"
                type="text">
            </div>
          </div>
        </div>
      </div>
    </article>
    <vehicle-lookup-modal v-if="isVehicleLookupModalActive"
      :active.sync="isVehicleLookupModalActive"
      v-model="innerVehicle.rego"
      :state-rego="innerVehicle.stateRego"
      @close="isVehicleLookupModalActive = false"
      @select="selectVehicleMakeModel">
      <p slot="text-title">Make/Model Lookup</p>
    </vehicle-lookup-modal>
    <car-model-search-modal :visible="isCarModelSearchModalActive"
      :make="innerVehicle.model ? innerVehicle.model.make : ''"
      :model="innerVehicle.model ? innerVehicle.model.model : ''"
      :series="innerVehicle.model ? innerVehicle.model.series : ''"
      :is-ntar="isOrm || isNtar"
      @ok="selectMakeModelModal"
      @cancel="isCarModelSearchModalActive = false" />
  </div>
</template>

<script>
import Vue from 'vue'
import _cloneDeep from 'lodash.clonedeep'
import _debounce from 'lodash.debounce'
import { VehicleLookupModal } from '@/components/VehicleLookupModal'
import { VehicleService } from '@/services'
import { BulmaAutocomplete } from '@/components/BulmaAutocomplete'
import { CarModelSearchModal } from '@/components/carmodel'
import UnscheduledSearchModal from '@/components/carmodel/UnscheduledModelModal'
import { mapGetters } from 'vuex'
import { TransmissionTypes, PaintGroupTypes, EventHubTypes, LabourTimeTypes, StateRegoTypes } from '@/enums'
import Guid from '@/components/Guid'
// import storeMixin from '../../storeMixin'
import { InputValidationDirective } from '@/components/directives'
import Multiselect from 'vue-multiselect'

export default {
  name: 'QuoteVehicle',
  inject: ['$vv'],
  components: {
    VehicleLookupModal,
    BulmaAutocomplete,
    CarModelSearchModal,
    Multiselect
  },
  mixins: [InputValidationDirective],
  props: {
    value: null,
    quote: null
  },
  data: () => {
    return {
      innerVehicle: null,
      innerQuote: null,
      isVehicleLookupModalActive: false,
      selectedMakeModel: null,
      filteredMakeModels: [],
      makeModelText: '',
      isSearchingMakeModel: false,
      isCarModelSearchModalActive: false,
      months: {
        1: '01',
        2: '02',
        3: '03',
        4: '04',
        5: '05',
        6: '06',
        7: '07',
        8: '08',
        9: '09',
        10: '10',
        11: '11',
        12: '12'
      },
      vinTokens: {
        '#': {
          pattern: /[0-9a-zA-Z]/
        }
      },
      vinMask: Array(18).join('#'), // length of vin field
      engineSizeTokens: {
        P: {
          pattern: /[0-9, .]/
        }
      },
      engineSizeMask: Array(5).join('P'),
      vehicles: [],
      vehicleFilter: {
        vehicleId: Guid.empty(),
        rego: '',
        pageIndex: 1,
        pageSize: 50
      },
      isSearchingVehicle: false,
      ntarLtarStatus: '',
      selectedVehicle: null
    }
  },
  computed: {
    $v() {
      return this.$vv || this.$v
    },
    transmissionTypes: function () {
      return TransmissionTypes
    },
    stateRegoTypes: function () {
      return StateRegoTypes
    },
    paintGroupTypes() {
      return PaintGroupTypes
    },
    ...mapGetters('vehicleOptions', {
      storeBodyShapes: 'bodyShapes',
      storeColours: 'colours',
      storePaintGroups: 'paintGroups'
    }),
    paintGroups: function () {
      // return this.$store.getters['vehicleOptions/paintGroups']
      return this.storePaintGroups
    },
    modelId: function () {
      if (this.innerVehicle && this.innerVehicle.model && this.innerVehicle.model.id) {
        return this.innerVehicle.model.id
      }
      return ''
    },
    bodyStyles: function () {
      // return this.$store.getters['vehicleOptions/bodyShapes']
      return this.storeBodyShapes
    },
    bodyStyles2: function () {
      const bodyShape = this.bodyStyles.find((d) => d.id === this.innerVehicle.bodyId)

      if (bodyShape) {
        return bodyShape.shapes
      }
      return []
    },
    colours: function () {
      const input = this.innerVehicle && this.innerVehicle.colour ? this.innerVehicle.colour.toLowerCase() : ''
      return this.storeColours.filter((d) => d.toLowerCase().indexOf(input) > -1)
    },
    isOrm() {
      return this.innerQuote.orm !== null && this.innerQuote.orm !== undefined
    },
    isNtar() {
      return (
        this.innerQuote.labourType === LabourTimeTypes.NTAR ||
        this.innerQuote.labourType === LabourTimeTypes.LTAR ||
        this.innerQuote.labourType === LabourTimeTypes.eMTA
      )
    }
  },
  watch: {
    'innerQuote.odometer': function (newVal, oldVal) {
      this.$emit('update:quote', this.innerQuote)
    },
    'innerQuote.vehicleId': function (newVal, oldVal) {
      if (oldVal !== newVal) {
        this.getNtarLtarStatus()
      }
    },
    'innerVehicle.model': {
      handler: function (newVal, oldVal) {
        if (this.innerVehicle.model) {
          this.makeModelText = this.innerVehicle.model.name
          this.getNtarLtarStatusByScheduleBody()
        }
      },
      deep: true
    },
    'innerVehicle.bodyId': {
      handler: async function (newVal, oldVal) {
        this.getNtarLtarStatusByScheduleBody()
      }
    },
    'innerVehicle.bodyId2': {
      handler: async function (newVal, oldVal) {
        this.getNtarLtarStatusByScheduleBody()
      }
    },
    innerVehicle: {
      handler: function (newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
  created() {
    this.innerVehicle = _cloneDeep(this.value)
    this.innerQuote = _cloneDeep(this.quote)
    this.vehicleFilter.vehicleId = this.innerQuote.vehicleId
    if (this.innerVehicle && this.innerVehicle.model && this.innerVehicle.model.name) {
      this.makeModelText = this.innerVehicle.model.name
    }
    this.getNtarLtarStatus()
    this.getVehicleDropdown()
  },
  mounted() {
    this.$eventHub.$on(`${EventHubTypes.EntitySaved}-${this.$parent.$options.name}`, this.updateInnerVehicle)
    // this.$vv.vehicle.$touch()
  },
  beforeDestroy() {
    this.$eventHub.$off(`${EventHubTypes.EntitySaved}-${this.$parent.$options.name}`)
  },
  methods: {
    selectVehicleMakeModel(model, vehicle) {
      console.log(model, vehicle)
      this.innerVehicle.model = model
      this.innerVehicle.vin = vehicle.vin
      this.innerVehicle.colour = vehicle.colour
      this.innerVehicle.engineNo = vehicle.engine_number
      this.innerVehicle.buildYear = vehicle.year_of_manufacture
      this.innerVehicle.stateRego = vehicle.stateRego
      this.translateBodyType(vehicle.factory ? vehicle.factory.body : vehicle.body_type, this.innerVehicle)
      if (vehicle.factory) {
        this.innerVehicle.cylinders = vehicle.factory.cylinders
        this.innerVehicle.engineSize = vehicle.factory.litres
        this.innerVehicle.buildYear = vehicle.factory.buildYear
      }
      // this.selectedVehicle.rego = vehicle.plate.number
      // this.selectedVehicle.model = model.name
    },
    translateBodyType(bodyType, model) {
      if (bodyType.toLowerCase().includes('sedan')) {
        model.bodyId = '4'
        model.bodyId2 = '4S'
      } else if (bodyType.toLowerCase().includes('wagon')) {
        model.bodyId = 'W'
        model.bodyId2 = 'WG'
      }
    },
    searchMakeModel: _debounce(async function (query) {
      this.isSearchingMakeModel = true
      this.makeModelText = query
      this.filteredMakeModels = []
      if (query) {
        const filtered = await VehicleService.searchModelByName(query)
        this.filteredMakeModels = filtered.data.list
      }
      this.isSearchingMakeModel = false
    }, 500),
    selectMakeModel(val) {
      console.log(`${val} - ${this.makeModelText}`)
      if (val) {
        this.innerVehicle.model = val
        if (this.innerVehicle.model.bodies && this.innerVehicle.model.bodies.length > 0) {
          this.innerVehicle.bodyId = this.innerVehicle.model.bodies.find((b) => b && b !== ' ')
        }
        if (this.bodyStyles2 && this.bodyStyles2.length > 0) {
          const bodyStyle2 = this.bodyStyles2[0]
          this.innerVehicle.bodyId2 = bodyStyle2.id
        }
        if (this.innerVehicle.model.startYear) {
          this.innerVehicle.buildYear = this.innerVehicle.model.startYear
        }
        if (this.innerVehicle.model.startMonth) {
          this.innerVehicle.buildMonth = this.innerVehicle.model.startMonth
        }
      } else if (!val && this.makeModelText === '') {
        this.innerVehicle.model = null
      }
    },
    selectMakeModelModal(model) {
      if (model) {
        this.makeModelText = model.name
        this.innerVehicle.model = model
        if (this.innerVehicle.model.bodies && this.innerVehicle.model.bodies.length > 0) {
          this.innerVehicle.bodyId = this.innerVehicle.model.bodies.find((b) => b && b !== ' ')
        }
        if (this.bodyStyles2 && this.bodyStyles2.length > 0) {
          const bodyStyle2 = this.bodyStyles2[0]
          this.innerVehicle.bodyId2 = bodyStyle2.id
        }
        if (this.innerVehicle.model.startYear) {
          this.innerVehicle.buildYear = this.innerVehicle.model.startYear
        }
        if (this.innerVehicle.model.startMonth) {
          this.innerVehicle.buildMonth = this.innerVehicle.model.startMonth
        }
      }
      this.isCarModelSearchModalActive = false
    },
    addModel() {
      const UnscheduledSearchModalComponent = Vue.extend(UnscheduledSearchModal)
      const props = {
        el: document.createElement('div'),
        propsData: {
          visible: true,
          name: this.makeModelText,
          bodyStyles: this.storeBodyShapes // this.$store.getters['vehicleOptions/bodyShapes']
        }
      }
      new UnscheduledSearchModalComponent(props).$on('ok', (res) => {
        this.makeModelText = res.name
        this.innerVehicle.model = res
        this.innerVehicle.model.isNew = false
      })
    },
    copyModel() {
      const UnscheduledSearchModalComponent = Vue.extend(UnscheduledSearchModal)
      const props = {
        el: document.createElement('div'),
        propsData: {
          visible: true,
          bodyStyles: this.storeBodyShapes, // this.$store.getters['vehicleOptions/bodyShapes'],
          copyModelId: this.innerVehicle.model.id
        }
      }
      new UnscheduledSearchModalComponent(props).$on('ok', (res) => {
        this.makeModelText = res.name
        this.innerVehicle.model = res
        this.innerVehicle.model.isNew = false // ??
      })
    },
    editModel() {
      if (!this.innerVehicle.model.isUnscheduled) {
        return
      }

      const UnscheduledSearchModalComponent = Vue.extend(UnscheduledSearchModal)
      const props = {
        el: document.createElement('div'),
        propsData: {
          visible: true,
          bodyStyles: this.storeBodyShapes, // this.$store.getters['vehicleOptions/bodyShapes'],
          modelId: this.innerVehicle.model.id
        }
      }
      new UnscheduledSearchModalComponent(props).$on('ok', (res) => {
        this.makeModelText = res.name
        this.innerVehicle.model = res
      })
    },
    checkYear: function (e) {
      const year = e.target.value
      if (year && year.length === 2) {
        const strYear = `01/01/${year}`
        const newDate = new Date(strYear)
        this.innerVehicle.buildYear = newDate.getFullYear().toString()
      } else if (year.length !== 4) {
        this.innerVehicle.buildYear = ''
      }
    },
    selectPaintGroup(event) {
      if (event.target.value !== PaintGroupTypes.M3) {
        this.innerVehicle.paintGroupPlus = false
      }
      // console.log(event.target.value)
    },
    searchVehicle: _debounce(async function (query) {
      this.isSearchingVehicle = true
      this.vehicleFilter.rego = query
      this.innerVehicle.rego = query
      this.vehicles = await VehicleService.getVehicleDropdownProto(this.vehicleFilter)
      this.isSearchingVehicle = false
    }, 500),
    async selectVehicle(selected) {
      if (selected) {
        this.innerQuote.vehicleId = selected.vehicleId
        this.innerQuote.customerId = selected.customerId
        // Propagate to parent QuoteView to reload customer and vehicle
        // this.getQuoteCustomer({ id: selected.customerId, refresh: true })
        // this.getQuoteVehicle({ id: selected.vehicleId, refresh: true })
        this.vehicleFilter.vehicleId = selected.vehicleId
        this.vehicleFilter.rego = ''
        this.vehicles = await VehicleService.getVehicleDropdownProto(this.vehicleFilter)
        console.log(this.innerQuote.vehicleId)
        this.$emit('update:quote', this.innerQuote)
        this.$nextTick(() => {
          this.$emit('select-vehicle', selected.vehicleId, selected.customerId)
        })
      }
    },
    handleVehiclePickerEnter() {
      if (this.$company.info.locale === 'en-AU') {
        // get element with clas dropdown-item that also has class is-hovered
        const el = this.$refs.vehicleAutocomplete.$el
        const selected = el.querySelector('.dropdown-item.is-hovered')
        if (!selected) this.isVehicleLookupModalActive = true
      }
    },
    updateInnerVehicle() {
      console.log(`${this.$options.name} - ${this.value.modifiedDate}`)
      console.log(`${this.$options.name} - ${this.quote.modifiedDate}`)
      this.innerVehicle = _cloneDeep(this.value)
      this.innerQuote = _cloneDeep(this.quote)
      this.makeModelText = this.innerVehicle.model ? this.innerVehicle.model.name : ''
    },
    async getNtarLtarStatus() {
      this.ntarLtarStatus = await VehicleService.getNtarLtarStatusById(this.innerQuote.vehicleId)
    },
    async getNtarLtarStatusByScheduleBody() {
      if (
        this.innerVehicle.model &&
        this.innerVehicle.model.scheduleId &&
        this.innerVehicle.model.scheduleId.trim() &&
        this.innerVehicle.bodyId &&
        this.innerVehicle.bodyId.trim()
      ) {
        this.ntarLtarStatus = await VehicleService.getNtarLtarStatusByScheduleBody(this.innerVehicle.model.scheduleId, this.innerVehicle.bodyId)
      } else {
        this.ntarLtarStatus = ''
      }
    },
    async getVehicleDropdown() {
      this.isSearchingVehicle = true
      this.vehicles = await VehicleService.getVehicleDropdownProto(this.vehicleFilter)
      if (this.vehicleFilter.vehicleId !== Guid.empty()) {
        this.selectedVehicle = this.vehicles.find((c) => c.vehicleId === this.innerQuote.vehicleId)
      }
      this.isSearchingVehicle = false
    },
    getVehicleDropdownFilter: _debounce(async function (query) {
      this.isSearchingVehicle = true
      this.vehicleFilter.rego = query
      this.vehicles = await VehicleService.getVehicleDropdownProto(this.vehicleFilter)
      this.isSearchingVehicle = false
    }, 500),
    removeVehicle() {
      this.vehicleFilter.vehicleId = Guid.empty()
      this.innerQuote.vehicleId = Guid.empty()
      this.getVehicleDropdown()
    },
    async selectVehicle1(selected) {
      console.log('selectVehicle1', selected.vehicleId, selected.customerId)
      this.innerQuote.vehicleId = selected.vehicleId
      this.innerQuote.customerId = selected.customerId
      // this.removeQuoteCustomer()
      // this.removeQuoteVehicle()
      // this.getQuoteCustomer({ id: selected.customerId, refresh: false })
      // this.getQuoteVehicle({ id: selected.vehicleId, refresh: true })
      this.vehicleFilter.vehicleId = selected.vehicleId
      this.vehicleFilter.rego = ''
      this.vehicles = await VehicleService.getVehicleDropdownProto(this.vehicleFilter)
      this.$emit('update:quote', this.innerQuote)
      this.$nextTick(() => {
        this.$emit('select-vehicle', selected.vehicleId, selected.customerId)
      })
    },
    onChangeBodyStyle() {
      const bodyStyle2 = this.bodyStyles2[0]
      this.innerVehicle.bodyId2 = bodyStyle2.id
    }
  },
  beforeRouteEnter(to, from, next) {
    next()
  },
  beforeRouteLeave(to, from, next) {
    next()
  }
}
</script>

<style lang="scss" scoped>
div.tile.is-parent {
  min-height: calc(100vh - 8rem);
}

.columns.field {
  margin-bottom: 0;
  .column {
    // padding-bottom: 0;
    &:first-child {
      padding-right: 0.375rem;
    }
    &:last-child {
      padding-left: 0.375rem;
    }
    :not(:first-child):not(:last-child) {
      padding-left: 0.375rem;
      padding-right: 0.375rem;
    }
  }
}
.is-divider {
  margin: 0 0 1.2rem 0;
}
</style>
